exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-daftar-js": () => import("./../../../src/pages/daftar.js" /* webpackChunkName: "component---src-pages-daftar-js" */),
  "component---src-pages-hadir-js": () => import("./../../../src/pages/hadir.js" /* webpackChunkName: "component---src-pages-hadir-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terima-kasih-js": () => import("./../../../src/pages/terima-kasih.js" /* webpackChunkName: "component---src-pages-terima-kasih-js" */),
  "component---src-pages-tips-js": () => import("./../../../src/pages/tips.js" /* webpackChunkName: "component---src-pages-tips-js" */),
  "component---src-templates-post-js-content-file-path-contents-6-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/vercel/path0/contents/6.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-contents-6-mdx" */),
  "component---src-templates-post-list-js-content-file-path-contents-6-mdx": () => import("./../../../src/templates/postList.js?__contentFilePath=/vercel/path0/contents/6.mdx" /* webpackChunkName: "component---src-templates-post-list-js-content-file-path-contents-6-mdx" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */)
}

